import React, { ChangeEvent } from 'react';
import { TextContainer, SwitchContainer, Title, Container, Subtitle } from './styles';
import { useIntl } from 'react-intl';
import { usePresetWindowToggle } from './hooks/use-preset-window-toggle.hook';
import { SwitchV2 } from '@hqo/react-components-library/dist/molecules/switch-v2';

interface PresetWindowToggleProps {
  onChangeToggle?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const PresetWindowToggle = ({ onChangeToggle }: PresetWindowToggleProps): JSX.Element => {
  const intl = useIntl();
  const { checked, onChangeHandler, isDisabled } = usePresetWindowToggle(onChangeToggle);

  return (
    <Container>
      <SwitchContainer>
        <SwitchV2 disabled={isDisabled} checked={checked} onChange={onChangeHandler} hideIcons />
      </SwitchContainer>
      <TextContainer>
        <Title disabled={isDisabled}>{intl.formatMessage({ id: 'all_day' })}</Title>
        <Subtitle disabled={isDisabled}>{intl.formatMessage({ id: 'hours_may_vary' })}</Subtitle>
      </TextContainer>
    </Container>
  );
};
