import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectAppInstanceConfigs,
  selectCurrentAppInstanceConfigUuid,
  selectCurrentLocationId,
} from 'store/app-instance-configs/selectors';
import { DefaultFilterEnum } from '../shared/consts';
import { ResourceBookingEnum } from 'store/app-instance-configs/enums';
import { ResourceBookingConfigs } from 'store/app-instance-configs/types';

interface TermsAndConditionsResponse {
  isTermsAndConditionsEnabled: boolean;
  termsAndConditionsText: string;
}

interface UseAppInstanceConfigFeatureReturnValues {
  isAvailableNowEnabled: boolean;
  isPresetBookingWindowsEnabled: boolean;
  termsAndConditionsValues: TermsAndConditionsResponse;
  isMultiDayBookingEnabled: boolean;
  defaultFilter: DefaultFilterEnum;
  isCompactUiEnabled: boolean;
  isNativeAdapter: boolean;
  hideWhenIsBooking: boolean;
  isMapViewEnabled: boolean;
}

export const useAppInstanceConfigFeature = (): UseAppInstanceConfigFeatureReturnValues => {
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const currentLocationId = useSelector(selectCurrentLocationId);
  const {
    showResourceBookingTermsAndConditions,
    showResourceBookingFullDayToggle,
    resourceBookingEnableMultiDayFeature,
    resourceBookingShowMapView,
  } = useFlags();
  const { pathname } = useLocation();

  const parseLocationIds = (locationIdString: string): Array<string> => {
    return locationIdString ? locationIdString.split(',').map(id => id.trim()) : [];
  };

  const currentLocationIds = parseLocationIds(currentLocationId);

  const currentAppInstanceConfigs =
    currentLocationIds.length > 0
      ? appInstanceConfigs.filter(
          config => config.uuid === currentAppInstanceConfigUuid && currentLocationIds.includes(config.location_id),
        )
      : appInstanceConfigs.filter(config => config.uuid === currentAppInstanceConfigUuid);

  const allConfigsHaveTruthyValue = useCallback(
    (key: keyof ResourceBookingConfigs): boolean => {
      return currentAppInstanceConfigs.every(config => config.config?.[key]);
    },
    [currentAppInstanceConfigs],
  );

  const isPresetBookingWindowsEnabled = useMemo((): boolean => {
    if (pathname.includes('/floorplan/')) {
      return (
        appInstanceConfigs.find(appInstanceConfig => appInstanceConfig.config?.preset_booking_windows_enabled) &&
        showResourceBookingFullDayToggle
      );
    }
    return allConfigsHaveTruthyValue('preset_booking_windows_enabled') && showResourceBookingFullDayToggle;
  }, [allConfigsHaveTruthyValue, appInstanceConfigs, pathname, showResourceBookingFullDayToggle]);

  const termsAndConditionsValues = useMemo((): TermsAndConditionsResponse => {
    return {
      isTermsAndConditionsEnabled:
        allConfigsHaveTruthyValue('terms_and_conditions_enabled') && showResourceBookingTermsAndConditions,
      termsAndConditionsText: currentAppInstanceConfigs?.[0]?.terms_and_conditions,
    };
  }, [allConfigsHaveTruthyValue, currentAppInstanceConfigs, showResourceBookingTermsAndConditions]);

  const isMultiDayBookingEnabled = useMemo((): boolean => {
    return allConfigsHaveTruthyValue('multi_day_booking_enabled') && resourceBookingEnableMultiDayFeature;
  }, [allConfigsHaveTruthyValue, resourceBookingEnableMultiDayFeature]);

  const defaultConfigValues = useMemo(() => {
    return {
      defaultFilter: currentAppInstanceConfigs[0]?.config?.default_filter,
      isCompactUiEnabled: allConfigsHaveTruthyValue('compact_ui_enabled'),
      isAvailableNowEnabled: allConfigsHaveTruthyValue('available_now_enabled'),
      isNativeAdapter: currentAppInstanceConfigs.every(
        config => config.vertical_adapter?.name === ResourceBookingEnum.NativeAdapter,
      ),
      hideWhenIsBooking: allConfigsHaveTruthyValue('hide_when_is_booking'),
    };
  }, [allConfigsHaveTruthyValue, currentAppInstanceConfigs]);

  const isMapViewEnabled = useMemo((): boolean => {
    return (
      (allConfigsHaveTruthyValue('portfolio_resources_enabled') ||
        currentAppInstanceConfigs.every(
          config => config.vertical_adapter?.name === ResourceBookingEnum.EssensysAdapter,
        )) &&
      resourceBookingShowMapView
    );
  }, [allConfigsHaveTruthyValue, currentAppInstanceConfigs, resourceBookingShowMapView]);

  return {
    ...defaultConfigValues,
    isPresetBookingWindowsEnabled,
    termsAndConditionsValues,
    isMultiDayBookingEnabled,
    isMapViewEnabled,
  };
};
