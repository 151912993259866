import { selectGetResourcesLoadingStatus } from 'store/resources/selectors';
import { BOOLEAN_STRINGS, DefaultFilterEnum } from 'shared/consts/const';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppInstanceConfigFeature } from './use-app-instance-config-feature.hook';

interface UseFilterModalReturnValues {
  isLoadingResources: boolean;
  isMobileDevice: boolean;
  isOverlayVisible: boolean;
  toggleOverlay: VoidFunction;
  contentHeight: number;
  setContentHeight: React.Dispatch<React.SetStateAction<number>>;
  defaultFilter: DefaultFilterEnum;
  isActive: boolean;
  isPresetWindowFilterVisible: boolean;
}

export const useFilterModal = (): UseFilterModalReturnValues => {
  const isLoadingResources = useSelector(selectGetResourcesLoadingStatus);
  const isMobileDevice = useIsSmallViewportWidth();
  const { showResourceBookingFullDayToggle } = useFlags();
  const { startDate, filterByType, capacity, filterByFloor, availableNow } = useSearchParams<searchParams>();
  const { value: isOverlayVisible, toggle: toggleOverlay } = useBooleanState(false);
  const [contentHeight, setContentHeight] = useState<number>();
  const { isPresetBookingWindowsEnabled, defaultFilter } = useAppInstanceConfigFeature();
  const isActive = useMemo<boolean>(
    () => !!(startDate || filterByType || capacity || filterByFloor || availableNow === BOOLEAN_STRINGS.TRUE),
    [startDate, filterByType, capacity, filterByFloor, availableNow],
  );
  const isPresetWindowFilterVisible: boolean = showResourceBookingFullDayToggle && isPresetBookingWindowsEnabled;

  return {
    isLoadingResources,
    isMobileDevice,
    isOverlayVisible,
    toggleOverlay,
    contentHeight,
    setContentHeight,
    defaultFilter,
    isActive,
    isPresetWindowFilterVisible,
  };
};
