import { useDispatch } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'components/time-selector/time-selector.interface';
import { PresetWindows } from 'store/resources/types';
import { ChangeEvent, useCallback } from 'react';
import { pick } from 'utils/pickObjectProperty';
import { QUERY_PARAMS } from 'shared/consts';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';

interface UsePresetWindowToggleReturnValues {
  checked: boolean;
  onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  isDisabled: boolean;
}

export const usePresetWindowToggle = (
  onChangeToggle?: (event: ChangeEvent<HTMLInputElement>) => void,
): UsePresetWindowToggleReturnValues => {
  const dispatch = useDispatch();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { pathname } = useLocation();
  const { presetWindows, startDate, startDates, ...restQueryParams } = useSearchParams<searchParams>();
  const checked: boolean = presetWindows === PresetWindows.FULL_DAY;
  const isDisabled = !(startDate || startDates);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (showResourceDetailsDateTimeInputs && !pathname.includes('filter-by')) {
        onChangeToggle(event);
      } else {
        const presetWindowsValue = event.target.checked ? PresetWindows.FULL_DAY : undefined;
        const filteredParams = pick(
          restQueryParams,
          QUERY_PARAMS.filter(param => param !== 'duration' && param !== 'startTime' && param !== 'endTime'),
        );
        const queryParams = {
          ...filteredParams,
          startDate,
          startDates,
          presetWindows: presetWindowsValue,
        };
        const queryString = qs.stringify(queryParams);
        dispatch(replace(`${location.pathname}?${queryString}`));
      }
    },
    [dispatch, restQueryParams, startDate, startDates],
  );

  return { checked, onChangeHandler, isDisabled };
};
